section.social-media {
    background-color: transparent; // Fallback
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    color: $text-white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: adjust-color($dark, $alpha: -0.3);
    }

    .social-tab {
        position: relative;

        nav {
            ul {
                list-style: none;

                li {

                    label {
                        border-bottom: 0.125rem solid transparent;
                        color: darken($text-white, 30%);

                        &:hover {
                            color: $text-white;
                        }

                        &:active {
                            color: $text-white;
                            border-bottom-color: $red;
                        }
                    }
                }
            }
        }

        section {
            flex: 1 1 100%;
        }

        > input,
        section > div {
            display: none;
        }
    }

    #tab-facebook:checked ~ section .tab-facebook,
    #tab-instagram:checked ~ section .tab-instagram {
        display: block;
    }

    #tab-facebook:checked ~ nav .tab-facebook,
    #tab-instagram:checked ~ nav .tab-instagram {
        label {
            color: $text-white;
            border-bottom-color: $red;
        }
    }
}
