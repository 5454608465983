// Define colors (Tailwind)
$white: #ffffff;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$black: #000000;

$red-50: #fef2f2;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-400: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-700: #b91c1c;
$red-800: #991b1b;
$red-900: #7f1d1d;

$yellow-50: #fffbeb;
$yellow-100: #fef3c7;
$yellow-200: #fde68a;
$yellow-300: #fcd34d;
$yellow-400: #fbbf24;
$yellow-500: #f59e0b;
$yellow-600: #d97706;
$yellow-700: #b45309;
$yellow-800: #92400e;
$yellow-900: #78350f;

$blue-50: #eff6ff;
$blue-100: #dbeafe;
$blue-200: #bfdbfe;
$blue-300: #93c5fd;
$blue-400: #60a5fa;
$blue-500: #3b82f6;
$blue-600: #2563eb;
$blue-700: #1d4ed8;
$blue-800: #1e40af;
$blue-900: #1e3a8a;

// CI/CD Colors
// #E20714
$red: hsl(356.4, 94%, 45.7%);
// 1) #A10014 - 2) #C4040F
$red-gradient-1: hsl(352.5, 100%, 31.6%);
$red-gradient-2: hsl(356.6, 96%, 39.2%);
$red-gradient: linear-gradient(to left, $red-gradient-1, $red-gradient-2);
$red-gradient-trans: linear-gradient(to left, adjust-color($red-gradient-1, $alpha: -0.2), adjust-color($red-gradient-2, $alpha: -0.2));
// #272E31
$dark: hsl(198, 11.4%, 17.3%);
// #515D62
$light-blue: hsl(197.6, 9.5%, 35.1%);
// #F3F3F3
$light-bronze: hsl(0, 0%, 95.3%);

// Text colors
$text-gray: $light-blue;
$text-red: $red;
$text-white: $light-bronze;

// Bootstrap settings
$enable-rounded: false;

// Bootstrap colors
$theme-colors: (
    "primary": $red,
    "secondary": $dark,
);

// Bootstrap Grid Settings
$grid-columns: 12;
$grid-gutter-width: 60px;

$grid-breakpoints: (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 992px,
    // Extra large screen / wide desktop
    xl: 1200px
);

$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 1400px,
    xl: 1660px
);

// Spacings
$spacer: 0.25rem !default;
$spacers: (
    0: 0,
    1: $spacer,
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    6: ($spacer * 6),
    7: ($spacer * 7),
    8: ($spacer * 8),
    9: ($spacer * 9),
    10: ($spacer * 10),
    11: ($spacer * 11),
    12: ($spacer * 12),
    14: ($spacer * 14),
    16: ($spacer * 16),
    20: ($spacer * 20),
    24: ($spacer * 24),
    28: ($spacer * 28),
    32: ($spacer * 32),
    36: ($spacer * 36),
    40: ($spacer * 40),
    44: ($spacer * 44),
    48: ($spacer * 48),
    52: ($spacer * 52),
    56: ($spacer * 56),
    60: ($spacer * 60),
    64: ($spacer * 64),
    72: ($spacer * 72),
    80: ($spacer * 80),
    96: ($spacer * 96),
);
