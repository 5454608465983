section.in-page-navigation {
    .card-grid {
        color: $text-white;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: minmax(0, 1fr);

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include media-breakpoint-up(md) {
            grid-gap: 2rem
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .card {
            color: $white;

            &:hover {
                color: $white;
            }

            .card-img-overlay {
                top: auto;
                height: 100%;
                text-align: center;
                background: adjust-color($dark, $alpha: -0.1);
                background: linear-gradient(180deg, transparent 0%, adjust-color($dark, $alpha: -0.4) 100%);

                &:hover {
                    .card-content {
                        .card-title {
                            bottom: 0.25rem;
                            transform: scale(1.05);
                        }
                    }
                }

                .card-content {
                    position: relative;
                    height: 100%;

                    .card-title {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin-bottom: 0;
                        transition: all .25s ease-in-out;
                    }
                }

            }
        }
    }
}
