// Default font style
html {
    font-size: 112.5%; // 18px
}

body {
    font-family: 'Cera Pro', Arial, Helvetica, sans-serif;
    color: $text-gray;
}

// Specific font style
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'DIN Next LT Pro, Medium Condensed', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-size: 2rem;
    line-height: 0.95;
    margin-bottom: 1.75rem;

    @include media-breakpoint-up(md) { font-size: 3rem; }
    @include media-breakpoint-up(lg) { font-size: 4rem; }
    @include media-breakpoint-up(xl) { font-size: 4.5rem; }
}

h2 {
    font-size: 1.75rem;
    line-height: 1.05;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) { font-size: 2.25rem; }
    @include media-breakpoint-up(lg) { font-size: 2.75rem; }
    @include media-breakpoint-up(xl) { font-size: 3.25rem; }
}

h3 {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 1.35rem;

    @include media-breakpoint-up(md) { font-size: 1.75rem; }
    @include media-breakpoint-up(lg) { font-size: 2.125rem; }
    @include media-breakpoint-up(xl) { font-size: 2.5rem; }
}

h4 {
    font-size: 1.25rem;
    line-height: 1.3;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) { font-size: 1.25rem; }
    @include media-breakpoint-up(lg) { font-size: 1.5rem; }
    @include media-breakpoint-up(xl) { font-size: 1.75rem; }
}

h5 {
    font-size: 1.125rem;
    line-height: 1.35;
    font-weight: bold;
    margin-bottom: 0.75rem;

    @include media-breakpoint-up(md) { font-size: 1.125rem; }
    @include media-breakpoint-up(lg) { font-size: 1.25rem; }
    @include media-breakpoint-up(xl) { font-size: 1.5rem; }
}

h6 {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(md) { font-size: 1.125rem; }
    @include media-breakpoint-up(lg) { font-size: 1.125rem; }
    @include media-breakpoint-up(xl) { font-size: 1.25rem; }
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 0.9em;
}

p {
    font-size: 1rem;
    line-height: 1.65;
    font-family: 'Cera Pro', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

small {
    font-size: 0.75rem;
}

blockquote,
blockquote p,
q,
abbr,
address,
cite {
    font-size: 1.125rem;
    line-height: 1.5;
    font-family: 'Cera Pro', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: italic;

    @include media-breakpoint-up(md) { font-size: 1.25rem; }
}

a {
    img {
        text-decoration: none;
    }

    svg {
        height: 1.5rem;
        transform: translateY(-0.125rem);
    }
}

.content-text {
    *:last-child {
        margin-bottom: 0;
    }
}

.lead {
    @media screen and (max-width: 768px) {
        font-size: 1.1rem;        
    }
}