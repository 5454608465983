// https://css-tricks.com/snippets/css/using-font-face/

// WOFF2
// Chrome 36+ | Safari NO | Firefox 35+ (with flag) | Opera 23+ | IE NO | Android 37 | iOS NO

// WOFF2 & WOFF
// Chrome 5+ | Safari 5.1+ | Firefox 3.6+ | Opera 11.50+ | IE 9+ | Android 4.4+ | iOS 5.1+

// WOFF2 & WOFF & TTF
// Chrome 3.5+ | Safari 3+ | Firefox 3.5+ | Opera 10.1+ | IE 9+ | Android 2.2+ | iOS 4.3+


@font-face {
    font-family: 'Cera Pro';
    src: url('../../fonts/CeraProRegular/CeraProRegular.woff2') format('woff2'), // Super Modern Browsers
         url('../../fonts/CeraProRegular/CeraProRegular.woff') format('woff'); // Pretty Modern Browsers
    font-weight: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('../../fonts/CeraProBold/CeraProBold.woff2') format('woff2'), // Super Modern Browsers
        url('../../fonts/CeraProBold/CeraProBold.woff') format('woff'); // Pretty Modern Browsers
    font-weight: bold;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('../../fonts/CeraProRegularItalic/CeraProRegularItalic.woff2') format('woff2'), // Super Modern Browsers
        url('../../fonts/CeraProRegularItalic/CeraProRegularItalic.woff') format('woff'); // Pretty Modern Browsers
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro, Medium Condensed';
    src: url('../../fonts/DINNextLTProMediumCond/DINNextLTProMediumCond.woff2') format('woff2'), // Super Modern Browsers
        url('../../fonts/DINNextLTProMediumCond/DINNextLTProMediumCond.woff') format('woff'); // Pretty Modern Browsers
    font-weight: normal;
    font-style: normal;
}

.font-sans-serif {
    font-family: Arial, Helvetica, sans-serif;
}

.font-serif {
    font-family: 'Times New Roman', Times, serif;
}
