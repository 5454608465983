section.poi-contact {
    .background {
        background-color: $gray-100;
    }

    ul {
        list-style-type: none;

        li {
            svg {
                color: $gray-500;
            }
        }
    }
}
