.check {
    /* Customize the label (the container) */
    display: block;
    position: relative;
    padding-left: 2.125rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        // Height and width are needed to show warning when not checked
        height: 1px;
        width: 1px;

        // So that warning aligns with custom checkbox
        top: 1.5rem;
        left: 0.75rem;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.5rem;
        width: 1.5rem;
        background-color: $white;
        border: 0.125rem solid $gray-200;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 7px;
        top: 4px;
        width: 0.385rem;
        height: 0.625rem;
        border: solid $text-gray;
        border-width: 0 0.125rem 0.125rem 0;
        transform: rotate(45deg);
    }
}

select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    &:-ms-expand {
        display: none;
    }

    &.custom-select {
        border: 1px solid $gray-300;
        background-color: $white;
        height: auto;
        margin-bottom: 1rem;
    }
}

.form-component {
    form {
        select {
            height: auto;
            border: 2px solid $gray-300;
        }

        .form-check {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    .alert {
        margin-bottom: 1.5rem;
        padding: 1rem;
    }

    ul.errors {
        padding-left: 0;
        list-style: none;
        font-weight: bold;
    }
}