.slider {
    .slider-item {
        width: 50%; /* half-width */
        margin-right: 1rem;
        position: relative;

        img {
            width: 100%;
            object-fit: cover;
        }

        .slider-item-image-overlay {
            position: static;
            @include media-breakpoint-up(md) {
                position: absolute;
                top: 0;
                left: 0;
            }
            background-color: adjust-color($dark, $alpha: -0.2);
            color: $text-white;
            padding: 0.5rem 1rem;

            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.slider-full-width {
        .slider-item {
            width: 100%;
            margin-right: 0;
        }
    }

    .flickity-button {
        border-radius: 0;
        background-color: adjust-color($gray-400, $alpha: -0.7);

        &.flickity-prev-next-button {
            width: 2.5rem;
            height: 2.5rem;

            @include media-breakpoint-up(md) {
                width: 3rem;
                height: 3rem;
            }

            &.previous {
                left: 0;
            }

            &.next {
                right: 0;
            }
        }

        .flickity-button-icon {
            fill: $gray-200;
            stroke-width: 2px;
            width: 40%;
            height: 40%;
            top: 30%;
            left: 30%;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.25rem adjust-color($gray-400, $alpha: -0.7);
        }

        /* hide disabled button */
        &:disabled {
            display: none;
        }
    }

    /* position dots in carousel */
    .flickity-page-dots {
        bottom: 1rem;

        /* white circles */
        .dot {
            width: 8px;
            height: 8px;
            opacity: 1;
            background: adjust-color($gray-400, $alpha: -0.4);
            border: none;

            /* fill-in selected dot */
            &.is-selected {
                background: $white;
            }
        }
    }

    .static-banner {
        background-color: adjust-color($gray-400, $alpha: -0.7);

        h2 {
            font-size: 1.5rem;

            @include media-breakpoint-up(md) {
                font-size: 2.5rem;
            }
        }

        p {
            font-size: 1rem;
            margin: 1.5rem 0 0;

            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
        }

        a {
            color: $text-white;
            font-size: 1rem;
            font-weight: bold;
            display: block;
            margin: 1.5rem 0 0 0;
            text-transform: uppercase;
            text-decoration: none;

            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }

            &:hover {
                color: darken($text-white, 10%);
            }

            &:focus {
                outline: none;
                color: darken($text-white, 20%);
            }

            svg {
                margin-left: 0.5rem;
            }
        }
    }

    &.slider-primary {
        .static-banner {
            background-color: adjust-color($red, $alpha: -0.2);
        }

        .flickity-button {
            background-color: adjust-color($red, $alpha: -0.4);

            .flickity-button-icon {
                fill: $text-white;
            }
        }
    }
}
