section.poi-call-to-action {
    .background {
        background-color: transparent; // Fallback
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: adjust-color($red, $alpha: -0.2);
        }
    }

    color: $text-white;
}
