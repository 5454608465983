section.shop-filtered-articles {
    &.bg-light-gray {
        background-color: $light-bronze;
    }

    .filters {
        .form-group {
            .form-control {
                height: 3rem;
            }
        }
    }

    .articles {
        flex: 1 1 100%;

        .card-grid {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: minmax(0, 1fr);

            @include media-breakpoint-up(sm) { grid-template-columns: repeat(2, minmax(0, 1fr)); }
            @include media-breakpoint-up(md) { grid-template-columns: repeat(3, minmax(0, 1fr)); }
            @include media-breakpoint-up(lg) { grid-template-columns: repeat(4, minmax(0, 1fr)); }
            @include media-breakpoint-up(xl) { grid-template-columns: repeat(5, minmax(0, 1fr)); }

            h2 {
                // h5 styling
                font-size: 1.125rem;
                line-height: 1.35;

                @include media-breakpoint-up(md) { font-size: 1.125rem; }
                @include media-breakpoint-up(lg) { font-size: 1.25rem; }
                @include media-breakpoint-up(xl) { font-size: 1.5rem; }
            }
        }
    }

    .more-function {
        flex: 1 1 100%;
    }
    anchor {
        position:relative;
        top: -150px;
    }

}
