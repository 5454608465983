// Move POI page up, only if slider is present
section.slider + main.poi-details {
    @include media-breakpoint-up(md) {
        //transform: translateY(-3rem);
        margin-top: -3rem;
    }
}

main.poi-details {
    .info-block {
        word-break: break-word;
    }

    .logo-line {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .poi-logos {
        img {
            max-height: 6rem;
            background-color: $white;
            padding: 1rem;
            margin-right: 1rem;
            margin-bottom: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
