.website-header {
    position: sticky;
    top: 0;
    z-index: 9999;
    width: 100%;
    background-color: $white;
    box-shadow: 0 0 5px hsla(0, 0, 0, 0.2);

    // Prevent wiggling of navbar by adding a transparent scrollbar
    &::-webkit-scrollbar {
        background: transparent;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.website-header-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100px;

    /* special padding for header area */
    @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .logo {
        padding: 0.25rem 0;
        max-width: 7.5rem;

        @include media-breakpoint-up(lg) {
            max-width: none;
        }
    }
}
