.text-transform-none {
    text-transform: none;
}

// cookies accordion style
.accordion .open-icon { display: none; }
.accordion .close-icon { display: block; }
.accordion [aria-expanded='true'] .open-icon { display: block; }
.accordion [aria-expanded='true'] .close-icon { display: none; }

// flex gaps
.gap-2 {
    gap: 0.25rem;
}
.gap-4 {
    gap: 0.5rem;
}
.gap-6 {
    gap: 0.75rem;
}
.gap-8 {
    gap: 1rem;
}

/* prioritize modal backdrop over navigation z-index */
.modal-backdrop {
    z-index: 10010;
}
.modal {
    z-index: 10020 !important;

    .modal-header,
    .modal-footer {
        border-color: #f2f2f2;
    }

    .modal-body {
        *:last-child {
            margin-bottom: 0;
        }
    }
}

// datepicker style overrides
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    border-color: #f64747 !important;
    background-color: #f64747 !important;
}

x-cloak {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}


// Bootstrap Offcanvas (not available as component in Vesion 4.6)
.offcanvas {
    position: fixed;
    z-index: 9999 !important;
    overflow-y: scroll;
}
.offcanvas-collapse {
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
  }

  .offcanvas-collapse.open {
    visibility: visible;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }