/*
  Player Skin Designer for Video.js
  http://videojs.com

  To customize the player skin edit
  the CSS below. Click "details"
  below to add comments or questions.
  This file uses some SCSS. Learn more
  at http://sass-lang.com/guide)

  This designer can be linked to at:
  https://codepen.io/heff/pen/EarCt/left/?editors=010
*/
.video-js {
    // The following are SCSS variables to automate some of the values.
    // But don't feel limited by them. Change/replace whatever you want.

    // The color of icons, text, and the big play button border.
    // Try changing to #0f0
    $primary-foreground-color: $white; // #fff default

    // The default color of control backgrounds is mostly black but with a little
    // bit of blue so it can still be seen on all-black video frames, which are common.
    // Try changing to #900
    $primary-background-color: $red; // #2B333F default

    // Try changing to true
    $center-big-play-button: true; // true default

    // The base font size controls the size of everything, not just text. All
    // dimensions use em-based sizes so that the scale along with the font size.
    // Try increasing it to 15px and see what happens.
    font-size: 1rem;

    // The main font color changes the ICON COLORS as well as the text
    color: $primary-foreground-color;

    // The "Big Play Button" is the play button that shows before the video
    // plays. To center it set the align values to center and middle. The
    // typical location of the button is the center, but there is trend towards
    // moving it to a corner where it gets out of the way of valuable content
    // in the poster image.
    &.vjs-default-skin .vjs-big-play-button {
        transition: all 150ms ease-in-out;

        .vjs-icon-placeholder:before {
            content: '';
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke="white" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" /></svg>');
            background-repeat: no-repeat;
            background-size: 2.3em;
            background-position: 50% calc(50% - 0px);
            border: none !important;
            box-shadow: none !important;
        }

        // The font size is what makes the big play button...big. All
        // width/height values use ems, which are a multiple of the font size.
        // If the .video-js font-size is 10px, then 3em equals 30px.
        font-size: 3em;

        // We're using SCSS vars here because the values are used in multiple
        // places. Now that font size is set, the following em values will be a
        // multiple of the new font size. If the font-size is 3em (30px), then
        // setting any of the following values to 3em would equal 30px.
        // 3 * font-size.
        $big-play-width: 2em;
        // 1.5em = 45px default
        $big-play-height: 2em;

        line-height: $big-play-height;
        height: $big-play-height;
        width: $big-play-width;

        // 0.06666em = 2px default
        border: 0.09999em solid $primary-foreground-color;
        // 0.3em = 9px default
        border-radius: 100%;

        &:before {
            content: '';
            display: none;
        }

        //&:hover {
        //    background-color: transparent;
        //    opacity: .7;
        //}

        @if $center-big-play-button {
            // Align center
            left: 50%;
            top: 50%;
            margin-left: -($big-play-width / 2);
            margin-top: -($big-play-height / 2);
        } @else {
            // Align top left. 0.5em = 15px default
            left: 0.5em;
            top: 0.5em;
        }
    }

    // The default color of control backgrounds is mostly black but with a
    // little bit of blue so it can still be seen on all-black video frames,
    // which are common.
    .vjs-control-bar,
    .vjs-big-play-button,
    .vjs-menu-button .vjs-menu-content {
        // IE8 - has no alpha support
        background-color: $primary-background-color;
        // Opacity: 1.0 = 100%, 0.0 = 0%
        background-color: rgba($primary-background-color, 0.7);
    }

    // Make a slightly lighter version of the main background for the slider
    // background.
    $slider-bg-color: lighten($primary-background-color, 33%);

    // Slider - used for Volume bar and Progress bar
    .vjs-slider {
        background-color: $slider-bg-color;
        background-color: rgba($slider-bg-color, 0.5);
    }

    // The slider bar color is used for the progress bar and the volume bar (the
    // first two can be removed after a fix that's coming)
    .vjs-volume-level,
    .vjs-play-progress,
    .vjs-slider-bar {
        background: $primary-foreground-color;
    }

    // The main progress bar also has a bar that shows how much has been loaded.
    .vjs-load-progress {
        // For IE8 we'll lighten the color
        background: lighten($slider-bg-color, 25%);
        // Otherwise we'll rely on stacked opacities
        background: rgba($slider-bg-color, 0.5);
    }

    // The load progress bar also has internal divs that represent smaller
    // disconnected loaded time ranges
    .vjs-load-progress div {
        // For IE8 we'll lighten the color
        background: lighten($slider-bg-color, 50%);
        // Otherwise we'll rely on stacked opacities
        background: rgba($slider-bg-color, 0.75);
    }
}
