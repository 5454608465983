.cartcounter {
    // For the cart icon in menu (mobile)
    top: 20px;
    left: 20px;

    height: 20px;
    width: 20px;
    background-color: $text-red;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
    text-align: center;
    color: $white;
    font-size: 15px;

    // For the cart icon in menu (desktop)
    @include media-breakpoint-up(lg) {
        top: -16px;
        left: 7px;
    }
}

.lineItems-qty {
    width: 40px;
}

.row-form .formwrapper {
    label, input, select, textarea {
        width: 100%;
    }

    input {
        height: 33px;
    }
}

.hidden {
    display: none;
}

.required label:after {
    content:"*";
}
.required .text-red {
    color: $text-red;
}
