.related-pois-container {
    .sprig-component {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        a.poi {
            width: 100%;

            &:hover {
                text-decoration: none;
            }
    
            h1 {
                font-size: 1.25rem;
            }
        }
    }

    .load-more-pois {
        grid-column: 1/-1;
        margin: 0 auto;
        max-width: 15rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}