.poi-finder-hero {
    background-color: $white; // Fallback
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .filter {
            max-width: 35rem;

            .filter-box {
                background-color: $red;
                color: $white;
                padding: 2.5rem;
                margin-bottom: 2rem;

                h2 {
                    font-size: 2.5rem;

                    svg {
                        height: 2rem;
                        margin-right: 0.25rem;
                        transform: translateY(-0.25rem);
                    }
                }

                p {
                    margin-bottom: 0.5rem;
                }

                .input-labeled {
                    display: block;
                    position: relative;

                    svg {
                        display: inline-block;
                        width: 2rem;
                        height: 2rem;
                        stroke-width: 1;
                        stroke: currentColor;
                        fill: none;

                        position: absolute;
                        top: 50%;
                        right: 1rem;
                        transform: translateY(-50%);
                        color: $red;
                    }

                    input {
                        height: auto;
                        width: 100%;
                        margin-bottom: 1rem;

                        padding-right: calc(1.25rem + 1rem + 1.5rem); /* icon width + icon padding-left + desired separation*/
                    }
                }

                a {
                    display: block;
                    color: $white;

                    svg {
                        height: 1.5rem;
                        margin-right: 0.5rem;
                        transform: translateY(-0.125rem);
                    }
                }
            }

            .tip-box {
                background-color: $dark;
                color: $white;
                padding: 2.5rem;

                h2 {
                    font-size: 2.5rem;

                    svg {
                        height: 2rem;
                        transform: translateY(-0.25rem);
                    }
                }
            }
        }

        .content {
            color: $white;
            max-width: 40rem;

            @include media-breakpoint-up(md) {
                margin-left: 7rem;
            }

            a {
                svg {
                    height: 1.5rem;
                    margin-left: 0.25rem;
                    transform: translateY(-0.125rem);
                }
            }
        }
    }
}
