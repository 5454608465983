section.info-bar {
    nav {
        .breadcrumb {
            .breadcrumb-item + .breadcrumb-item:before {
                // #d1d5db is '$gray-300'
                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="%23d1d5db"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>');
                height: 1.5rem;
                width: 1.5rem;
                transform: translateY(0.125rem);
            }

            .breadcrumb-item {
                a {
                    color: $gray-300;
                }

                &.active {
                    color: $gray-400;
                }
            }
        }
    }

    .social {
        .social-share {
            font-size: 1.5rem;
        }
    }
}
