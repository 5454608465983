.related-shop-articles {
    background-color: $light-bronze;

    h1 {
        flex: 1 1 100%;

        // So that title, link and slider button are in one line
        @include media-breakpoint-up(md) {
            flex: initial;
        }
    }

    // Previous, Next buttons for slider
    .slider-actions {
        button {
            padding: 0.5rem 0.625rem;
        }
    }

    .articles {
        flex: 1 1 100%;

        .slider {
            .slider-item {
                width: 50%;
                min-height: 100%;

                @include media-breakpoint-up(lg) {
                    width: 32.5%;
                }
            }
        }
    }

    .articles-mobile {
        flex: 1 1 100%;
    }

    // Same styling for the items in .articles and .articles-mobile
    .slider-item,
    .item {
        border: 0.125rem solid $gray-200;

        img {
            width: 30%;
        }

        > div {
            // The right block should take remaining space
            flex: 1 1 0%;

            p {
                line-height: 1;
            }

            a {
                font-size: 1rem;
            }

            .title {
                line-height: 1.2;
            }

            .input-group {
                flex-direction: column;
                @media all and (min-width: 480px) {
                    flex-direction: row;
                }
                @include media-breakpoint-up(md) {
                    flex-direction: column;
                }
                @media all and (min-width: 1440px) {
                    flex-direction: row;
                }

                .form-control {
                    padding: 0.5rem 0.75em;
                    width: 100%;
                    flex: 1 1 0%;
                    @media all and (min-width: 480px) {
                        padding: 1.25rem 1rem;
                        width: 1%;
                        flex: 1 1 auto;
                    }
                    @include media-breakpoint-up(md) {
                        padding: 0.5rem 0.75em;
                        width: 100%;
                        flex: 1 1 0%;
                    }
                    @media all and (min-width: 1440px) {
                        padding: 1.25rem 1rem;
                        width: 1%;
                        flex: 1 1 auto;
                    }
                }

                .input-group-append {
                    margin-left: 0;
                    margin-top: 0.5rem;
                    @media all and (min-width: 480px) {
                        margin-left: -1px;
                        margin-top: 0;
                    }
                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                        margin-top: 0.5rem;
                    }
                    @media all and (min-width: 1440px) {
                        margin-left: -1px;
                        margin-top: 0;
                    }

                    .btn {
                        width: 100%;
                        @media all and (min-width: 480px) {
                            width: initial;
                        }
                        @include media-breakpoint-up(md) {
                            width: 100%;
                        }
                        @media all and (min-width: 1440px) {
                            width: initial;
                        }
                    }
                }
            }
        }
    }
}

.produkt-teaser {
    .produkt-teaser-element {
        h3 {
            font-size: 1.5rem;
        }

        p {
            line-height: 1;
        }
    }
}