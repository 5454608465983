.main-navigation {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    
    .main-navigation-link-style {
        font-size: 0.95rem;
        text-transform: uppercase;
        border-bottom: 6px solid $white;
        color: $black;
        position: relative;
        margin-left: 1em;
        margin-right: 1em;
        display: flex;
        align-items: center;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            box-shadow: none;
            border-bottom: 6px solid $red;
            color: $red;
        }

        &.active {
            border-bottom: 6px solid $red;
            background-color: #f2f2f2;
        }
    }

    > .main-navigation-link-style {
        @include media-breakpoint-down(md) { 
            display: none;
        }
    }
}

.meta-navigation {
    padding-left: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    @include media-breakpoint-down(md) { 
        padding-left: 0;
        display: none;
    }

    .meta-link {
        padding: 0.75rem;
        position: relative;

        .shopping-cart-link {
            position: absolute;
            top: 0;

            .sprig-component {
                position: relative;
            }
        }
    }

    .dropdown-toggle {
        &:focus {
            box-shadow: none;
        }
        &:after {
            display: none;
        }
    }
    .dropdown-menu {
        min-width: none;
    }
}

.meta-navigation-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .languages {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.mobile-nav-toggler {
    display: none;

    &:focus {
        box-shadow: none !important;
    }
    
    @include media-breakpoint-down(md) { 
        display: flex;
    }

    .closed-icon {
        display: block;
    }
    .opened-icon {
        display: none;
    }

    &.open {
        .closed-icon {
            display: none;
        }
        .opened-icon {
            display: block;
        }
    }
}

.search-button {
    &:focus {
        box-shadow: none !important;
    }
}

.main-navigation-dropdown {
    position: absolute;
    top: 100px; //header height
    left: 0;
    width: 100%;
    padding: 2rem 2rem 1rem 2rem;
    background-color: #f2f2f2;
    border-bottom: 1px solid #dfdfdf;
    z-index: 99;
    transition: all 0.3s ease-in-out;
    transform-origin: center top;
    transform: scaleY(0);
    overflow-y: scroll;
    max-height: calc(100vh - 100px); //subtract header height

    &.open {
        transform: scaleY(1);
    }

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    .nav-block {
        > a:first-of-type {
            font-weight: bold;
        }

        a.level-1 {
            font-size: 1.5rem;
            display: inline-block;
            margin-bottom: 1.5rem;
        }

        a.level-2 {
            font-weight: bold;
        }
        .wrapper-2 {
            margin-bottom: 1rem;
        }

        a.level-3 {
            font-size: 0.9rem;
        }
    }

    .close-navigation {
        text-transform: none;
    }
}

.mobile-main-navigation {
    position: absolute;
    top: 100px; //header height
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #f2f2f2;
    padding: 1.5rem;
    transition: all 0.3s ease-in-out;
    transform-origin: center top;
    transform: scaleY(0);
    overflow-y: scroll;
    max-height: calc(100vh - 100px); //header height

    &.open {
        transform: scaleY(1);
    }

    @include media-breakpoint-up(lg) {
        display: none !important;
    }

    .sub-block-opener {
        border: 0;
        background-color: #f2f2f2;
        transition: transform 0.3s;
        transform-style: preserve-3d;

        &.open {
            transform: rotateX(180deg);
        }
    }
    
    .sub-block {
        margin-left: 1rem;
        display: none;

        &.open {
            display: block;
        }
    }

    .link-level-1 {
        font-size: 1.2rem;
        display: inline-block;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .link-level-2 {
        font-size: 1rem;
    }
    .link-level-3 {
        font-size: 0.9rem;
    }
}

[data-cloak] {
    display: none;
}