.search-fields {
    .filters {

        .filter-title {
            &:hover {
                cursor: pointer;
            }
        }

        .filter-wrapper-opener {
            font-size: 1.25rem;

            &:hover {
                text-decoration: none;
            }

            .open-icon {
                display: inline-block;
            }
            .close-icon {
                display: none;
            }

            &.open {
                .open-icon {
                    display: none;
                }
                .close-icon {
                    display: inline-block;
                }
            }
        }

        .filter-wrapper {
            display: none;
        }
    }
}

.poi-search-results {
    .poi-card {
        &:hover {
            text-decoration: none;
        }

        .poi-title {
            font-size: 1.25rem;
        }
    }
}