.search-opener {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
.search-block {
    width: 100%;
    background-color: $light-bronze;
    transition: all 0.3s ease-in-out;
    transform-origin: center top;
    transform: scaleY(0);
    position: fixed;
    top: 100px; //header height
    left: 0;
    width: 100%;
    z-index: 99;
    overflow-y: scroll;
    max-height: calc(100vh - 100px); //subtract header height

    &.open {
        transform: scaleY(1);
    }

    .form-control {
        height: auto;
    }
}
.close-search {
    text-transform: none;
}