.slider {
    &.type-1 {
        .slider-main {
            .slider-item {
                height: 20rem;

                @include media-breakpoint-up(md) {
                    height: 30rem;
                }

                &:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.25);
                    content: "";
                    display: block;
                }

                .slider-title {
                    @extend h1;
                    color: $text-white;
                }
                .slider-subtitle {
                    @extend h2;
                    color: $text-white;
                }

                .slider-item-image-overlay {
                    z-index: 999;
                    background: none;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }

        .slider-description {
            .slider-item {
                p {
                    max-width: 50rem;
                }
            }
        }
    }

    &.type-2 {
        .slider {
            .slider-item {
                height: 35rem;

                @include media-breakpoint-up(md) {
                    height: 40rem;
                }
                @include media-breakpoint-up(lg) {
                    height: 50rem;
                }

                .slider-title {
                    @extend h1;
                    color: $text-white;
                }
                .slider-subtitle {
                    @extend h2;
                    color: $text-white;
                }

                .slider-item-image-overlay {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);

                    color: $text-white;
                    padding: 3rem 0;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        padding: 3rem 6rem;
                        width: auto;
                    }

                    @include media-breakpoint-up(lg) {
                        max-width: 60rem;
                    }

                    a {
                        svg {
                            height: 1.5rem;
                            transform: translateY(-0.125rem);
                        }
                    }
                }
            }
        }
    }

    &.type-3 {
        .slider {
            .slider-item {
                height: 30rem;

                @include media-breakpoint-up(md) {
                    height: 40rem;
                }

                .slider-title {
                    @extend h1;
                    color: $text-white;
                }
                .slider-subtitle {
                    @extend h2;
                    color: $text-white;
                }

                .slider-item-image-overlay {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);

                    color: $text-white;
                    padding: 1rem;

                    @include media-breakpoint-up(md) {
                        padding: 3rem 6rem;
                        transform: translate(-50%,calc(-50% - 1.5rem));
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            /* position dots in carousel */
            .flickity-page-dots {
                bottom: 1rem;

                @include media-breakpoint-up(md) {
                    bottom: 4rem;
                }
            }
        }
    }

    &.type-4 {
        .slider {
            .slider-item {
                height: 20rem;
                color: $text-white;

                @include media-breakpoint-up(md) {
                    height: 30rem;
                }

                .slider-title {
                    @extend h1;
                    color: $text-white;
                }
                .slider-subtitle {
                    @extend h2;
                    color: $text-white;
                }

                .slider-item-image-overlay {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    left: 0;
                    max-width: 100%;

                    @include media-breakpoint-up(md) {
                        max-width: 60%;
                    }

                    > * {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
