.btn {
    text-transform: uppercase;
    //padding: 0.75rem 1.5rem;
    //overflow-wrap: break-word;

    svg {
        height: 1.25rem;
        //transform: translateY(-0.125rem);
        //margin-left: 0.25rem;
    }

    &.btn-link {
        text-decoration: none !important;
    }
}
