.events-detailpage {
    .event-headerimage {
        width: 100%;
        max-height: 25rem;
        object-fit: cover;
        object-position: top center;
        border: 1px solid #f2f2f2;
    }
    .event-title {
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
        color: $gray-900;
    }
    .block-label {
        font-size: 0.8rem;
        text-transform: uppercase;
        color: $gray-400;
    }
    .content-info {
        color: $gray-900;
    }

    .badge {
        font-size: 0.8rem;
        font-weight: normal;
        padding: 0.25rem 0.75rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: $gray-100;
        color: $gray-800;
    }
}

.events-component {
    .card {
        margin-bottom: 1rem;

        &:hover {
            text-decoration: none;
        }

        .date-label {
            right: 0;
        }

        .category {
            border: 1px solid #f2f2f2;
        }

        .card-img {
            width: 100%;
            height: 12rem;
            object-fit: cover;
            object-position: top center;
        }

        .card-content {
            padding: 1rem;
            background-color: #f2f2f2;
        }

        .card-text {
            color: $gray-700;
        }

        .card-link {
            text-transform: uppercase;
        }

        svg {
            transform: none;
        }
    }
}

// discover swiss
.events-discoverswiss {
    
    .filter-loading-indicator {
        position: absolute;
        inset: 0;
        //background-color: rgba(1, 1, 1, 0.15);
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .event-filters {
        &.htmx-request {
            display: flex;
        }
    }    
}


.events-overview {
    .event-filters {
        background-color: #f2f2f2;
        position: relative;

        .filter-loading-indicator {
            display: none;
        }

        &.htmx-request {
            #eventSearchInput,
            .remove-datefilter,
            .remove-all-filters,
            .category-filter,
            .flatpickr-input {
                opacity: 0.3;
            }

            //overlay to block click interaction
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .filter-loading-indicator {
                display: block;
                position: absolute;
                left: calc(50% - 12px); //half icon width
                top: 50%;
            }
        }

        .filter-set {
            .filterlabel {
                color: #8b8b8b;
            }

            input[type="checkbox"] {
                &:checked {
                    & + .category-filter {
                        background-color: $red;
                        color: #fff;
                    }
                }
            }

            .category-filter-wrapper {
                display: inline-block;

                .category-filter {
                    display: inline-block;
                    background-color: #fff;
                }
            }
        }
    }
    
    .event-list {
        .event-list {
            .event-teaser {
                h1 {
                    font-size: 2rem;
                }
            }
        }
    
    }
}

// event datepicker overrides
.flatpickr-calendar {
    border-radius: unset;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: $red;
    border-color: darken($red, 10%);
}
span.flatpickr-day.startRange, span.flatpickr-day.prevMonthDay.startRange, span.flatpickr-day.nextMonthDay.startRange, span.flatpickr-day.endRange, span.flatpickr-day.prevMonthDay.endRange, span.flatpickr-day.nextMonthDay.endRange {
    border-color: darken($red, 10%);
}

//loading indicator
#indicator .loader {
    display: none;
}
#indicator.loading .loader {
    display: inline;
}
#indicator.loading .complete {
    display: none;
}


/* Discover Swiss */
.events-discoverswiss {
    #results {
        .events-component {
            > a {
                background: $light-bronze;

                .sponsored-label,
                .date-label {
                    width: fit-content;
                    padding: 0.25rem 0.75rem;
                    border: 1px solid $red;
                }

                .tags {
                    font-size: $font-size-base * 0.75;
                    color: $dark;
                }

                &.sponsored-event {
                    background-color: $red;
                    color: $white;

                    .card-title {
                        line-height: 1;
                    }

                    .sponsored-label,
                    .date-label {
                        background-color: $white;
                        color: $red;
                    }

                    .card-text {
                        color: $white;
                    }

                    .tags {
                        font-size: $font-size-base * 0.75;
                        color: $white;
                    }
                }

                
            }
        }
    }
}

#filterAccordion {
    .filter-button {
        .dropdown-icon {
            transition: transform 0.3s ease-in-out;
        }
        
        &[aria-expanded="true"] .dropdown-icon {
            transform: rotate(180deg); // Rotate when expanded
        }
        
        &.collapsed .dropdown-icon {
            transform: rotate(0deg); // Ensure it starts at 0deg when collapsed
        }
    }
}