.alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;

    svg {
        height: 1.75rem;
    }

    p {
        margin-bottom: 0;
    }

    a {
        font-weight: bold;
    }

    &.alert-warning {
        color: $yellow-700;
        background-color: $yellow-300;

        a {
            color: $yellow-900;
        }
    }

    &.alert-info {
        color: $blue-700;
        background-color: $blue-300;

        a {
            color: $blue-800;
        }
    }
}
