.bg-breadcrumb {
    background-color: lighten($light-bronze, 3%);
}

.breadcrumb {
    .breadcrumb-item {
        &+ .breadcrumb-item {
            &:before {
                color: $light-bronze;
            }
        }
    }
}