img,
iframe {
    display: block;
    max-width: 100%;
}

.image-credits {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 0.5rem;
    padding: 0.25rem 0.75rem;
    background-color: rgba(255,255,255,0.5);
    z-index: 999;
}