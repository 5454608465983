.poi-finder {
    .oax-top-cont {
        margin-top: 2rem;

        // General styling
        button,
        input {
            transition: color 150ms ease-in-out,
                        background-color 150ms ease-in-out,
                        border-color 150ms ease-in-out,
                        box-shadow 150ms ease-in-out !important;
            // "!important" is needed because ".oax .oax_view_controls button" overwrites it with "transition: none"
        }

        // Top row
        .oax-filter-selectors-wrapper {
            .oax-filter-selectors {
                background-color: transparent;
                padding: 0;
                margin-bottom: 0.5rem;

                // Search bar (container)
                .oax-selectors {
                    // Search bar
                    .oax-input-with-badge .oax_input_icon_left {
                        color: $red;
                        left: 3px;
                    }

                    .oax_input_icon {
                        padding: 12px 8px;
                    }

                    .oax_selector_input {
                        background-color: transparent;
                        border: 1px solid $gray-200;
                        border-radius: 0;
                        padding: 1.5rem 1.5rem 1.5rem 2.25rem;

                        &.input_active {
                            box-shadow: none;
                            background-color: $white;
                        }
                    }
                }
            }
        }

        // Filters row
        .oax-flexView-wrapper {
            .oax-filter-progress-cont {
                background-color: transparent;
                height: 4rem;

                // Pills on the left side
                .oax-filter-pills-wrapper {
                    // Filter button
                    .oax_pills_filter {
                        .oax-filter-pane-toggle {
                            border-radius: 0;
                            padding: 0.25rem 0.75rem;
                        }
                    }

                    // Filtered list
                    .oax_pills {
                        margin-left: 0;

                        .oax-filterdisplay-active {
                            span.oax_pills_active {
                                border-radius: 0;
                                color: $white;
                                text-transform: uppercase;
                                padding: 0.25rem 0.75rem;

                                .oax-icon-cross-circular {
                                    color: $white;
                                    opacity: 1;
                                }
                            }
                        }

                        .oax-filterdisplay-suggestions {
                            span {
                                border-radius: 0;
                                color: $black;
                                text-transform: uppercase;
                                padding: 0.25rem 0.75rem;
                            }
                        }

                        &.oax_pills_dark {
                            span {
                                background-color: $gray-600;
                                border: 1px solid $gray-600;
                            }
                        }

                        &.oax_pills_small {
                            &.oax_pills_filter {
                                span {
                                    &.oax_pills_active {
                                        background-color: $gray-500;
                                        border: 1px solid $gray-500;
                                    }
                                }
                            }

                            &.oax_pills_white {
                                span {
                                    border-color: $gray-200;
                                }
                            }
                        }
                    }
                }

                // Show ... results checkbox
                .oax_checkbox {
                    .oax-icon-checkmark {
                        border-color: $gray-400;
                        border-radius: 0;
                    }
                }

                // Fullscreen button at right
                .oax_mapbtns {
                    button {
                        background-color: $gray-400;
                        border-radius: 0;
                        margin-right: 0;

                        &:hover {
                            background-color: $gray-500;
                        }

                        &:focus {
                            box-shadow: 0 0 0 0.125rem hsla(0, 0%, 0%, 0.2);
                            border: none;
                        }
                    }
                }
            }

            .oax-flexView-container {
                // Map view
                .oax-map-container {
                    .oax-dp-list-container {
                        background-color: $gray-200;

                        // Gallery on left side
                        .oax_dp_list {
                            /* width */
                            &::-webkit-scrollbar {
                                width: 1.25rem;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: $gray-300;
                                border-left: 0.5rem solid $gray-200;
                                border-right: 0.5rem solid $gray-200;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $red;
                                border-left: 0.375rem solid $gray-200;
                                border-right: 0.375rem solid $gray-200;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: darken($red, 10%);
                            }

                            .oax-mapList {
                                // Each element
                                .oax_dp_snippet {
                                    border: none;
                                    border-radius: 0;
                                    background-color: $gray-50;
                                    box-shadow: none;

                                    &:hover {
                                        background-color: $gray-100;
                                    }

                                    // When using JS openOOIUrl with custom URL ...
                                    a {
                                        transition: border-color 150ms ease-in-out,
                                                    box-shadow 150ms ease-in-out;

                                        &:focus {
                                            border-radius: 0.375rem;
                                            box-shadow: 0 0 0 0.125rem hsla(0, 0%, 0%, 0.2);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Closing button for gallery on left
                    .oax_detailpane_toggle {
                        & > * {
                            transition: background-color 150ms ease-in-out;
                        }

                        .oax-toggle-dp-close {
                            background-color: $gray-200;
                        }

                        .oax-toggle-dp-open {
                            background-color: $gray-800;
                        }
                    }

                    // The map
                    .oax-map-canvas {
                        // Zoom in / Zoom out buttons
                        .leaflet-control-zoom {
                            border-radius: 0;

                            & > * {
                                border-radius: 0;
                                border: 0.125rem solid $gray-200;
                                transition: background-color 150ms ease-in-out,
                                            box-shadow 150ms ease-in-out;

                                &:hover {
                                    background-color: $gray-200;
                                    border: 0.125rem solid $gray-200;
                                }

                                &:focus {
                                    box-shadow: 0 0 0 0.125rem hsla(0, 0%, 0%, 0.2);
                                }

                                &:first-child {
                                    border-bottom: none;

                                    &:hover {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        // Map markers
                        .oax-cluster-marker-cont {
                            background-color: adjust-color($gray-600, $alpha: -0.4);
                        }
                    }
                }
            }
        }
    }
}

.oax {
    .oax-filter-dropdown-wrapper {
        border-radius: 0 !important;

        .oax_filter_pane {
            .oax_filter_pane_inner {
                /* width */
                &::-webkit-scrollbar {
                    width: 1.25rem;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: $gray-300;
                    border-left: 0.5rem solid $white;
                    border-right: 0.5rem solid $white;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $red;
                    border-left: 0.375rem solid $white;
                    border-right: 0.375rem solid $white;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: darken($red, 10%);
                }

                // Container with list
                .oax_toggle_cont {
                    border-radius: 0;
                }

                // Input
                .oax_input {
                    border-radius: 0;
                }

                // Checkbox
                .oax-icon-checkmark {
                    border-radius: 0;
                }
            }
        }
    }
}
