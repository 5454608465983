section.poi-downloads {
    .background {
        background-color: $gray-100;
    }

    .content {
        ul {
            list-style-type: none;

            li {
                svg {
                    min-width: 3rem;
                    max-width: 4rem;
                    color: $gray-500;
                }
            }
        }
    }
}
