.content-video {
    .video-wrapper {
        position: relative;
        
        &.video-youtube-wrapper,
        &.video-vimeo-wrapper {
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;
        }

        iframe {
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-file {
            width: 100%;
        }
    }
}