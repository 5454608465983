.debug {
    background-color: black;
    background-color: lighten($color: lime, $amount: 15%);
    color: lime;
    border: 1px solid lime;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: 'Consolas', 'Bitstream Vera Sans Mono', 'Courier New', Courier, monospace;
}
