.newsletter-signup {
    transform: translateY(50%);
    z-index: 1;
    max-width: 90%;
    margin-top: -10rem;
    background-color: $gray-100;
    color: $text-gray;

    @include media-breakpoint-up(sm) {
        margin-top: -8rem;
    }
    @include media-breakpoint-up(md) {
        max-width: 80%;
        margin-top: -6rem;
    }
    @include media-breakpoint-up(lg) {
        max-width: 70%;
    }
    @media all and (min-width: 1440px) {
        margin-top: -2rem;
    }

    h2 {
        font-size: 3rem;
    }

    form {
        flex: 1 1 100%;

        .inputs {
            flex: 1 1 100%;
            flex-direction: column;
            //@include media-breakpoint-up(lg) { flex-direction: row; }
            @media all and (min-width: 1440px) { flex-direction: row; }

            // Spacing
            > * {
                margin-top: 0.75rem;

                &:first-child {
                    margin-top: 0;
                }

                //@include media-breakpoint-up(lg) {
                @media all and (min-width: 1440px) {
                    margin-left: 0.75rem;
                    margin-top: 0;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            label {
                flex: 1 1 0%;

                // Make email field larger
                &.email {
                    flex: 1.2 1.2 0%;
                }
            }
            button {
                svg {
                    height: 1.5rem;
                    transform: translateY(-0.125rem);
                }
            }
        }
    }
}

footer.page-footer {
    position: relative;
    color: $text-white;
    background-color: transparent; // Fallback

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $red;
    }

    &.footer-background-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &:before {
            background-color: adjust-color($red, $alpha: -0.1);
        }
    }    

    a {
        color: $text-white;
        line-height: 1.25;

        &:hover {
            text-decoration: underline;
        }
    }

    .about {
        flex: 1 1 0%;

        @include media-breakpoint-up(md) {
            border-right: 1px solid adjust-color($light-bronze, $alpha: -0.6);
            min-width: 20rem;
        }
    }
    .other {
        flex: 2 2 0%;

        @include media-breakpoint-up(lg) {
            flex: 3 3 0%;
        }

        .service-navigation {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            //margin-right: -2rem; -> fix bug with overflowing container
            margin-left: -2rem;

            > div {
                width: 15rem;
                padding-left: 2rem;
                padding-right: 2rem;
                margin-bottom: 2rem;
            }

            h2 {
                font-size: 1.5rem;
                margin-bottom: 0;
            }

            ul {
                list-style-type: none;
            }
        }

        .contact-options {
            h2 {
                font-size: 3rem;
            }

            .contact-block {
                display: grid;
                grid-template-rows: repeat(4, min-content);

                @media all and (min-width: 1300px) {
                    grid-template-rows: initial;
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }

                background-color: $light-blue;

                p,
                a {
                    margin-bottom: 0;
                }
                .icon {
                    justify-self: center;
                    font-size: 2rem;
                }

                .social {
                    background-color: $dark;
                    justify-content: space-around;

                    a {
                        font-size: 1.65rem;
                        display: inline-block;

                        svg {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }

        .partner {
            h3 {
                font-size: 1.25rem;
                @include media-breakpoint-up(md) { font-size: 1.5rem; }
                @include media-breakpoint-up(lg) { font-size: 1.75rem; }
                @include media-breakpoint-up(xl) { font-size: 2rem; }
            }

            .partners {
                opacity: 0.8;

                a {
                    // img {
                    //     height: 3rem;
                    // }
                    .blkb-logo-image {
                        height: 3.75rem;
                    }
                    .bl-logo-image {
                        height: 3.5rem;
                    }
                }
            }

            .to-top {
                a {
                    svg {
                        height: 1.5rem;
                    }
                }
            }
        }
    }
}
