section.poi-blockquote {
    .blockquote {
        background-color: $gray-100;

        .quote {
            color: $text-red;
            height: auto;
            width: 3rem;
        }

        .blockquote-footer {
            &:before {
                content: '';
            }

            img {
                width: 4rem;
                border: 2px solid $gray-200;
            }
        }
    }

    .split-content {
        .blockquote-image {
            object-fit: cover;
        }

        .blockquote {
            @include media-breakpoint-up(lg) {
                max-width: 40%;
            }
        }
    }
}
