.gallery {
    &.type-1 {
        .slider-main {
            .static-banner {
                color: $text-white;
                background-color: $red;

                @include media-breakpoint-up(xl) {
                    background-color: adjust-color($red, $alpha: -0.2);
                    position: absolute;
                    z-index: 1;

                    top: 1.5rem;
                    bottom: 1.5rem;
                    left: 1.5rem;
                    max-width: 27rem;
                }
            }

            .slider-item-lightbox {
                position: relative;
                display: block;

                &:after {
                    // #e9ecef is $gray-200
                    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="%23e9ecef"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" /></svg>');
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                    width: 2.5rem;
                    height: 2.5rem;
                    padding: 0.5rem;
                    background-color: adjust-color($red, $alpha: -0.2);

                    @include media-breakpoint-up(md) {
                        width: 3rem;
                        height: 3rem;
                    }
                }
            }
        }

        .slider-nav {
            height: 6rem;

            @include media-breakpoint-up(md) {
                height: 9rem; // 4 / 3
            }

            .slider-item {
                width: 8rem; // 4 / 3
                height: 30rem;

                @include media-breakpoint-up(md) {
                    width: 12rem; // 4 / 3
                    height: 100%;
                }
            }
        }
    }

    &.type-2 {
        .slider {
            .slider-item {
                .slider-item-image {
                    min-height: 10rem;
                }

                .slider-item-image-overlay {
                    top: auto;
                    bottom: 1rem;
                    max-width: 30rem;
                }
            }
        }
    }
}
