.webcam-weather {
    //display: flex;
    //flex-direction: column;
    //width: 100%;
    color: $white;

    //@media screen and (min-width: 768px ) {
    //    flex-direction: row;
    //}

    .webcam-block {
        //width: 100%;
        padding: 3rem;
        background-color: $dark;

        //@media screen and (min-width: 768px ) {
        //    width: 70%;
        //}

        .carousel-cell {
            background-size: cover;
            background-position: center center;
            margin-right: 1rem;
            background-color: lighten($dark, 10%);
            width: 15rem;

            .webcam {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                min-width: 250px;
                height: 200px;
                padding: 1rem;

                &:hover {
                    text-decoration: none;
                }

                h4 {
                    font-size: 1.5rem;
                    line-height: 1;
                    margin-bottom: 0;
                    color: $white;
                    display: block;
                    text-shadow: 0px 0px 3px $dark;
                }
                span {
                    margin-top: -5px;
                    display: block;
                    color: $white;
                    text-shadow: 0px 0px 3px $dark;
                }
            }
        }
    }

    .weather-block {
        padding: 3rem;
        background-color: $red;

        .weather-day {
            font-size: 1.25rem;
        }
    }
}