section.call-to-action {
    background-color: transparent;
    color: $text-gray;

    &.cta-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        color: $text-white;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: adjust-color($red, $alpha: -0.2);
        }

        a {
            color: $text-white;
        }
        
        .content {
            img {
                min-height: 0;
                height: 12rem;
                flex: initial;
            }
        }
    }

    .content {
        > * {
            flex: 1 1 0%;
        }
    }
}
