.related-articles {
    .btn {
        svg {
            height: 1.5rem;
            transform: translateY(-0.125rem);
        }
    }

    .grid-list {
        flex: 1 1 100%;

        display: grid;
        gap: 2rem;
        grid-auto-rows: minmax(100px, auto);

        .element {
            color: $text-gray;

            &:hover {
                color: $text-red;
            }

            &:focus {
                outline: none;
                color: darken($text-red, 10%);
            }

            .thumbnail {
                height: 15rem;
                border: 1px solid $gray-200;

                @include media-breakpoint-up(md) {
                    height: 20rem;
                }

                @include media-breakpoint-up(lg) {
                    height: auto;
                }

                img {
                    object-fit: cover;
                }

                .category {
                    bottom: 0;
                    left: 0;
                }
            }

            h2 {
                font-size: 1.25rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }

    &.type-1 {
        .grid-list {
            grid-template-columns: minmax(0, 1fr);

            @include media-breakpoint-up(md) {
                gap: 3rem;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @include media-breakpoint-up(lg) {
                gap: 4rem;
                grid-template-columns: repeat(2, minmax(0, 2fr)) repeat(2, minmax(0, 3fr));
            }

            .element {
                &:first-child {
                    @include media-breakpoint-up(md) {
                        grid-column: 1 / span 2;
                    }

                    @include media-breakpoint-up(lg) {
                        grid-row: 1 / span 2;
                    }
                }
            }
        }
    }

    &.type-2 {
        .grid-list {
            grid-template-columns: minmax(0, 1fr);

            @include media-breakpoint-up(md) {
                gap: 3rem;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @include media-breakpoint-up(lg) {
                gap: 4rem;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
    }

    &.type-3 {
        .subtitle {
            font-family: 'DIN Next LT Pro, Medium Condensed', Arial, Helvetica, sans-serif;
            font-size: 1.25rem;
            line-height: 1.3;

            @include media-breakpoint-up(md) { font-size: 1.25rem; }
            @include media-breakpoint-up(lg) { font-size: 1.5rem; }
            @include media-breakpoint-up(xl) { font-size: 1.75rem; }
        }

        .grid-list {
            grid-template-columns: minmax(0, 1fr);

            @include media-breakpoint-up(md) {
                gap: 3rem;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @include media-breakpoint-up(lg) {
                gap: 4rem;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            .element {
                .thumbnail {
                    .category {
                        top: 0;
                        bottom: auto;
                    }
                }

                .element-body {
                    flex: 1 0 auto;
                }
            }
        }
    }
}
