section.shop-search {
    background-color: transparent; // Fallback
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    color: $text-white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: adjust-color($dark, $alpha: -0.5);
    }

    .content {
        padding-top: 5rem;
        padding-bottom: 5rem;

        form {
            max-width: 33rem;

            .search-field {
                flex-basis: auto;

                > div {
                    // Input has weird in-between (transparent) lines, so hide
                    // them with this
                    background-color: $gray-100;
                }
            }

            .filter-field {
                flex-basis: 5rem;
            }
        }
    }
}
