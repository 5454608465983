/* general sitespecific css */

//kampagne entdeckerland
.body-entdeckerlandAnmeldeseite {
    .kampagne-signup {
        form {
            select.form-control {
                padding: 0rem 1rem;
            }

            .alert {
                &.alert-danger {
                    margin-bottom: 1rem !important;

                    ul {
                        list-style-type: none;
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }
}