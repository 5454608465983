.offcanvas {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1050;

    @include media-breakpoint-up(md) {
        width: 50%;
    }
    @include media-breakpoint-up(lg) {
        width: 450px;
    }
}

.offcanvas.show {
    transform: translateX(0);
}

.offcanvas-end {
    right: 0;
}

.offcanvas-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.offcanvas-body {
    padding: 15px;
}

.btn-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.offcanvas-backdrop {
    overflow: hidden;
}